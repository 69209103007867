import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import randLogo from '../../../images/rand_logo.png';
import pittLogo from '../../../images/pitt_logo.png';

const hostName = window.location.hostname;
const Appdefs = window.cccisd.appDefs;
const wpUrl = Appdefs.app.wpUrl;

const Footer = props => {
    return (
        <div className={style.footer}>
            <div className={style.bottomfooter}>
                <div className={props.className}>
                    <div className={style.wrapper}>
                        <div>
                            The development of the IPSRT website was supported by The National Institute of Mental
                            Health grant R34MH091319
                        </div>
                        <div>
                            Software developed by{' '}
                            <a href={`//www.3cisd.com?r=${hostName}`} rel="noopener noreferrer" target="_blank">
                                3C Institute.
                            </a>{' '}
                            &copy; {props.copyrightYear}, All Rights Reserved.
                        </div>
                        {props.showPolicyLinks && (
                            <div>
                                <a href={wpUrl + 'contact'} rel="noopener noreferrer" target="_blank">
                                    Contact Us
                                </a>
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        )}
                        <div className={style.images}>
                            <img src={randLogo} alt="Rand Corporation" height="75px" width="75px" />
                            <img src={pittLogo} alt="University of Pittsburgh" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
};

export default Footer;
