import React from 'react';

const Appdefs = window.cccisd.appDefs;

export const getHomeRoute = () => {
    let route = '/';
    try {
        const homepageHandle = Appdefs.navs.find(nav => nav.homepage).homepage;
        route = Appdefs.routes.find(r => r.handle === homepageHandle).url;
    } catch (e) {
        route = '/';
    }

    return route;
};

export const oldCourseBanner = (
    <div
        style={{
            backgroundColor: ' #d2edef',
            marginBottom: '2em',
            padding: '1em',
            borderRadius: '5px',
            marginTop: '-2em',
        }}
    >
        Welcome to the new IPSRT site! If you previously completed the course on the old site, you can still access your
        continuing education certificate.
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5em' }}>
            <button type="button" className="btn btn-warning" onClick={() => window.open('https://legacy.ipsrt.org/')}>
                Access Previous Account
            </button>
        </div>
    </div>
);
