import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const isUber = userRole === 'uberadmin';
const wpUrl = Appdefs.app.wpUrl;

const AppHeader = ({ className }) => {
    const primaryNavLinks = [
        { url: wpUrl + 'overview', label: 'IPSRT Overview' },
        { url: wpUrl + 'testimonials', label: 'Testimonials' },
        { url: wpUrl + 'research', label: 'Research' },
        { url: Fortress.auth() ? Boilerplate.url('/survey/course') : Boilerplate.url('/login'), label: 'IPSRT Course' },
        { url: wpUrl + 'contact', label: 'Contact' },
    ];

    const uberAdminNav = [...primaryNavLinks, { url: '/manage', label: 'Manage' }];

    return (
        <Header
            containerClassName={className}
            wrapperClassName={style.wrapper}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={isUber ? uberAdminNav : primaryNavLinks}
            showAlertAction={false}
            showHomeAction={false}
        />
    );
};

AppHeader.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

AppHeader.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default AppHeader;
